<template>
  <div class="spinner-container" v-show="loaderVisible">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['initData'],
  computed: {
    loaderVisible : function(){
      let initLoader = this.initData.initLoader
      let loader = this.initData.loader
      if(initLoader===true || loader===true){
        return true
      }else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color:rgba(0, 0, 0, 0.5);
}
.loader {
  position: relative;
  width: 64px;
  height: 60px;
}
.loader::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
  animation: move 0.5s linear infinite alternate;
}
.loader::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  background: #ff6104;
  width: 24px;
  height: 24px;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% { transform: translateX(-50%) rotate(360deg)}
}
@keyframes move {
  0% { transform: rotate(10deg)}
  100% { transform: rotate(-10deg)}
}
</style>