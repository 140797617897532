<template>
  <main>
    <div class="container">
      <div class="row">
        <div v-if="selectedTask===null" class="col-12 select-task">
          <img class="mb-3" src="../assets/images/undraw_select_option_re_u4qn.svg" alt="Válassz feladatot.">
          <h3 class="mb-2">Melyik kihívással kezdenél?</h3>
          <button class="btn btn-primary mb-3" data-bs-toggle="modal" data-bs-target="#taskModal">Feladat választása</button>
          <p class="small">Később majd a menüben a feladat<br>nevére kattintva tudod változtatni.</p>
        </div>
        <div class="col-md-6">
          <div id="codeEditor">some text</div>
          <form @submit.prevent="commit(this,false)">
            <div class="row">
              <div class="col-sm-12">
                <div class="input-group mt-3">
                  <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                  <i class="bi bi-chat-dots"></i>
                              </span>
                  </div>
                  <input v-model="commitMsg" type="text" class="form-control" placeholder="Commit msg" aria-label="Commit msg" aria-describedby="basic-addon2" maxlength="100">
                  <div class="input-group-append">
                    <button class="btn btn-outline-dark code-commit-btn" type="submit"><i class="fas fa-code-branch"></i> Commit</button>
                  </div>
                </div>
                <div v-if="commitMsgError" class="small text-danger">
                  {{ commitMsgError }}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 task-text" v-html="taskData.taskText">
        </div>

      </div>
    </div>
  </main>
  <CommitForceModal></CommitForceModal>
</template>

<script>
import {Response} from '@/classes/response';
import {Validate} from "@/classes/validate";
import Ace from 'ace-builds/src-noconflict/ace'
import AceTheme from 'ace-builds/src-noconflict/theme-clouds'
import AceLanguage from 'ace-builds/src-noconflict/ext-language_tools'
import AceCpp from 'ace-builds/src-noconflict/mode-c_cpp'
import Information from "@/views/Information";
import CommitForceModal from "@/views/CommitForceModal";

export default {
  name: 'Game',
  props: ['initData'],
  components: {
    CommitForceModal,
    Information
  },
  data(){
    let tasks = this.initData.data.tasks
    let editor = null

    return {
      tasks,
      editor,
      commitMsg : null,
      commitMsgError : null,
      Information,
      CommitForceModal
    }
  },
  mounted() {
    let editor = Ace.edit('codeEditor')
    editor.setTheme("ace/theme/clouds")
    document.getElementById('codeEditor').style.fontSize='14px'
    editor.setOptions({
      'autoScrollEditorIntoView': true,
      'enableBasicAutocompletion': true,
      'maxLines': 45
    });
    editor.renderer.setScrollMargin(10, 10, 10, 10)
    editor.session.setMode('ace/mode/c_cpp')
    this.editor = editor

    let lastCode = this.initData.user.lastCode
    if(editor!==null && lastCode===null){
      editor.session.setValue("Válaszd ki a feladatot, amivel dolgozni szeretnél,\nés itt fog megjelenni a kezdő példakód ;)")
    }else{
      editor.session.setValue(lastCode)
    }
  },
  computed:{
    selectedTask(){
      let selectedTask = this.initData.user.selectedTask
      if(selectedTask===null){
        setTimeout(() => {
          Information.methods.openModal()
        }, 1000);
      }
      return selectedTask
    },
    taskData(){
      let selectedTask = this.selectedTask
      if(selectedTask===null){
        return {
          taskText : null
        }
      }

      return this.tasks.filter(function (el) {return el.taskId===selectedTask})[0]
    },
    lastCode(){
      return this.initData.user.lastCode
    }
  },
  watch:{
    selectedTask(){
      let selectedTask = this.initData.user.selectedTask
      this.taskData = this.tasks.filter(function (el) {return el.taskId===selectedTask})[0]
    },
    lastCode(){
      let lastCode = this.initData.user.lastCode
      console.log('lastCode change init')
      if(lastCode!==null && this.editor!==null){
        this.editor.session.setValue(lastCode)
        console.log('lastCode change init finish')
      }
    }
  },methods:{
    commit(e,commitUpdate) {
      let validate = new Validate()

      e.commitMsgError = validate.emptyField(e.commitMsg,'Nincs commit üzenet megadva.')

      if (e.commitMsgError) {
        return false;
      }

      let formData = new FormData()
      formData.append('code', e.editor.getValue())
      formData.append('commit_msg', e.commitMsg)
      formData.append('commit_update', commitUpdate)

      e.initData.loader = true
      e.$axios.post('/v2/qualifier/commit',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===false && responseData.msg===null){
              e.CommitForceModal.methods.openModal()
            }

            if(responseData.status===true) {
              const commitTimeOut = setTimeout(
                  () => e.getNewest(e,true),
                  15*1000)
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });

    },
    getNewest(e,isBackMsg) {
      console.log('get newest')

      let formData = new FormData()
      formData.append('dateTime', e.initData.user.lastRunDateTime)

      e.initData.loader = true
      e.$axios.post('/v2/qualifier/newest',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              let histories = responseData.data.histories
              if(histories.length>0){
                e.initData.user.histories.splice(0,0,...histories)
                e.initData.user.lastRunDateTime = histories[0].createDateTime
                if(isBackMsg===true){
                  e.initData.alerts.push({msg: 'A futtatás eredménye elkészült, nézd meg a futtatásoknál.', type: 'success'})
                }
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });

    }
  }
}
</script>
<style scoped>
#codeEditor {

}
main{
  padding-top: 1rem
}
.container{
  padding-bottom: 8rem;
}
.row{
  position: relative;
}
.select-task{
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  padding-top: 3rem;
  text-align: center;
  z-index: 100;
}
.select-task img{
  max-width: 40%;
}
</style>