<template>

  <footer>
    <div class="container">
      © 2017 - {{year}} GRAPHISOFT | C++ ITECH CHALLENGE
    </div>
  </footer>

</template>

<script>
export default {
  name: 'Footer',
  data(){
    let date = new Date();
    let year = date.getFullYear()
    return{
      year
    }
  }
}
</script>
<style scoped>
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 88px;
  background-color: #eee;
  padding: 2rem 0;
  text-align: center;
}
</style>