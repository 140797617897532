<template>
  <div class="modal fade" id="commitForceModal" tabindex="-1" role="dialog" aria-labelledby="commitForceModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="commitForceModalLabel"><i class="bi bi-git"></i> Force commit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <img class="mb-3" src="../assets/images/undraw_version_control_re_mg66.svg" alt="Commit force">
          <p>A Git repotokban van már egy újabb verzió ehhez a feladathoz, felülírod?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="bi bi-x-square"></i> Nem</button>
          <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" @click="Game.methods.commit(this.$parent,true)">Igen</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'
import Game from "@/views/Game";

export default {
  name: 'CommitForceModal',
  props: ['initData'],
  data() {
    return {
      modalInstance : null,
      Game
    }
  },
  methods:{
    openModal(){
      this.modalInstance = new Modal(document.getElementById('commitForceModal'))
      this.modalInstance.show()
    },
    closeModal(){
      this.modalInstance = new Modal(document.getElementById('commitForceModal'))
      this.modalInstance.hide();
    }
  }
}
</script>


<style scoped>
.modal-body img{
  max-width: 100%;
}
</style>