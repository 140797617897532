<template>

  <header>
    <nav class="navbar navbar-expand-md navbar-light bg-light shadow-sm">
      <div class="container">
        <router-link class="navbar-brand" :to="{name : 'game'}">
          <img src="../assets/images/graphisoft_itech_logo.svg" height="56" class="d-inline-block align-middle" alt="Graphisoft C++ ITech Challenge">
          <span class="title">Selejtező</span>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link"><strong>{{userData.teamName}}</strong></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-bs-toggle="modal" data-bs-target="#taskModal">
                <span v-if="selectedTask===null"><i class="bi bi-arrow-right-square"></i> feladat választása</span>
                <span v-else>{{selectTaskName}}</span>
              </a>
            </li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" data-bs-toggle="modal" data-bs-target="#informationModal"><i class="bi bi-info-square"></i> Információk</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" data-bs-toggle="modal" data-bs-target="#historyModal"><i class="bi bi-terminal"></i> Futtatások</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" :class="gitServerStatus ? 'text-success' : 'text-danger'" data-bs-toggle="modal" data-bs-target="#gitModal"><i class="bi bi-git"></i> Git</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" :href="landingPageUrl+'/eredmenyek'" target="_blank"><i class="bi bi-list-ol"></i> Eredmények</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="logOut()"><i class="bi bi-power"></i> Kilépés</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

</template>

<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";
import { Modal } from 'bootstrap'

export default {
  name: 'Header',
  props: ['initData'],
  data() {
    let tasks = this.initData.data.tasks
    let landingPageUrl = this.initData.data.landingPageUrl
    let gitServerStatus = this.initData.data.gitServerStatus
    let userData = this.initData.user
    let selectedTask = userData.selectedTask
    let selectTaskName = null
    if(selectedTask!==null){
      selectTaskName = tasks.filter(function (el) {return el.taskId===selectedTask})[0].name
    }
    return {
      userData,
      selectTaskName,
      tasks,
      landingPageUrl,
      gitServerStatus
    }
  },
  computed:{
    selectedTask(){
      return this.initData.user.selectedTask
    }
  },
  watch:{
    selectedTask(){
      let selectedTask = this.initData.user.selectedTask
      this.selectTaskName = this.tasks.filter(function (el) {return el.taskId===selectedTask})[0].name
    }
  },
  methods:{
    logOut(){
      this.initData.loader = true
      this.$axios.post('/v2/qualifier/logOut')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {

            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>
<style scoped>
.title{
  line-height: 63px;
}
.navbar-brand img{
  margin-right: 2rem;
}
.nav-link{
  cursor:pointer;
}
</style>