export class Validate{
    constructor(){
    }

    emptyField(value, errorText){
        if(value === null || value.length === 0){
            return errorText
        }
        return null
    }

    isSelected(value, errorText){
        if(value === undefined || value === 0 || value === '0' || value.length === 0){
            return errorText
        }
        return null
    }

}