<template>
  <div class="modal fade" id="informationModal" tabindex="-1" role="dialog" aria-labelledby="informationModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="informationModalLabel"><i class="bi bi-app-indicator"></i> Graphisoft C++ ITech Challenge 2022 selejtező</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-8">
              <h1>Kedves {{ userData.firstName }},</h1>
              <p>Köszöntünk a GRAPHISOFT C++ ITech Challenge 2022 selejtezőjében.</p>
              <p>Kérjük, hogy az itt található információkat olvasd el a rendszer használata előtt, később megtalálod az információk menüpont alatt.</p>
              <p class="small">frissítve: 2022.09.12 15:22:00</p>
              <h2><i class="bi bi-1-square"></i> Első lépés</h2>
              <p><strong>Első, és legfontosabb</strong>, hogy válasszátok ki, hogy melyik feladattal szeretnétek dolgozni, az online selejtezőn három feladat lesz.<br>
              Ezt bármikor, menet közben is változtathatjátok.</p>
              <p>Amikor kiválasztottátok a feladatot, és még nem dolgoztatok vele, mindig kapni fogtok egy példakódot, ami segít az elindulásban. Ha olyat választotok, amivel már dolgoztatok, akkor a legutolsó commitolt kód töltődik be. Nincs autosave, így a nem commitolt kód az oldal frissítése esetén nem mentődik el.</p>

              <h2><i class="bi bi-2-square"></i> Második lépés</h2>
              <p><strong>Második lépésként</strong> több lehetőségetek is van a kódotok megírására és futtatására. Vagy az online felületünket használjátok, és a commit gomb megnyomásával tudjátok elküldeni a szerverünkre fordítani, és futtatni.</p>
              <p>Vagy a GitLab online felületünket használjátok, és a commit gomb megnyomásával és a commit message megadásával tudjátok elküldeni a szerverünkre fordítani, és futtatni (lásd Git menüpont).</p>
              <p>Vagy a megadott GIT repo url és felhasználónév/jelszó segítségével (<strong>GIT leírást a Git menüpontra kattintva találtok</strong>) közvetlenül a repoba dolgoztok, és amikor pusholtok, akkor a szerverünk lefordítja és futtatja a kódokat.</p>
              <p>Nincs más teendőd, mint hogy írj egy jó kódot, és nyomd meg a Compile gombot.</p>
              <p><strong>Itt most mint csapat kell együtt dolgoznotok, tehát nem kell csapattagonként külön-külön megoldani a feladatokat, hanem csapatként kell megoldani minden feladatot.</strong></p>

              <h2><i class="bi bi-terminal"></i> Futtatások</h2>
              <p>A commitolás után a szerverünk észleli, hogy új kód került a repotokba, és elkezdi lefordítani, és futtatni.</p>
              <p>A futtatások eredményeit összefoglalva a Futtatás menüpontra kattintva találjátok, illetve a Git repotok main_log branchén.</p>
              <p>Szerverünk párhuzamosan dolgozza fel a beküldött kódokat, de előfordulhat, hogy néha a szokásosnál többet kell várni a futtatásokra.</p>
              <p>Az egész folyamat alatt több szervert és rendszert is használunk, amiket igyekszünk minnél nagyobb rendelkezésre állással üzemeltetni, de előfordulhatnak kisebb leállások, hálózati problémák. Ha fél órán belül nem kapnál választ, akkor mindenképp írj nekünk.</p>

              <h2><i class="bi bi-dice-5"></i> Pontozás</h2>
              <p>A feladatokat különbözőképpen pontozzuk, ennek leírását a feladatoknál különkülön megtalálod.</p>
              <p>Minden csapatnál feladatonként a legjobb elért eredményét vesszük figyelembe.</p>
              <p>Minden feladatnál minimum 5 pontot el kell érni.</p>
              <p>Az eredmények oldalunkon (Eredmények menüpont) folyamatosan figyelemmel kísérhetitek a csapatonkénti és feladatonkénti rangsort.</p>
              <p class="small">Az előfordulhat, hogy egy friss futtatás új pontszáma csak pár perces késéssel jelenik meg az összesítésben.</p>

              <h2><i class="bi bi-calendar4-range"></i> Időzítés</h2>
              <p>Megoldási határidő: 2022. október. 19 éjfél</p>
              <p class="small">A határidőn túl érkező megoldásokat nem fogadjuk el.</p>

              <h2><i class="bi bi-wind"></i> Továbbjutás</h2>
              <p>A legjobban teljesítő (és egyben minden feladatnál minimum 5 pontot elérő, összeségében minimum 100 pontot) 40 csapat jut tovább az online elődöntőbe.</p>
              <p></p>
              <p></p>
              <p>Jó programozást kívánunk!<br>
                Graphisoft C++ ITech Challenge csapata</p>
            </div>
            <div class="col-lg-4">
              <div class="card text-bg-primary mb-3">
                <div class="card-header text-white"><i class="bi bi-123"></i> Hogyan kezdjek neki?</div>
                <div class="card-body">
                  <p class="card-text text-white">Válaszd ki melyik feladattal szeretnél dolgozni.</p>
                  <p class="card-text text-white">Kiválasztás után megjelenik egy példakód, és a feladat leírása.</p>
                  <p class="card-text text-white">A példakódot, ha szeretnéd, rögtön commitolhatod is, hogy megnézd mi történik.</p>
                  <p class="card-text text-white">A kiinduló kódot tökélesítve érjetek el minél több pontot, hogy biztos legyen a továbbjutás.</p>
                  <p class="card-text text-white">A futtatások menüpontban láthatóak a commitolt kódok, hogy sikerült-e fordítani, és hogy mi lett a futtatások eredményei.</p>
                </div>
              </div>
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-cloud-fog2"></i> Fordító</div>
                <div class="card-body">
                  <p>g++ 9.3.0</p>
                  <p>boost 1.70 header only library-k használhatóak.</p>
                  <p>-std=c++17 -Wall -O2 -static -pthread</p>
                  <p class="small">Ubuntu szerveren fordítunk.</p>
                </div>
              </div>
              <div class="card text-bg-secondary mb-3">
                <div class="card-header"><i class="bi bi-life-preserver"></i> Support</div>
                <div class="card-body">
                  <p>Ha kérdésed van, vagy szokatlan, nem megfelelő működést tapasztalsz, bátran írd meg nekünk az <a href="mailto:info@itechchallenge.hu">info@itechchallenge.hu</a> e-mail címre.</p>
                  <p>Lehetőség szerint 1-2 munkanapon belül válaszolunk.</p>
                  <p>Az összes csapatot érintő fontosabb változásokról e-mailben értesítünk Benneteket.</p>
                  <p class="small">Más csatornát légyszíves ne használj, mivel alapvetően nem figyeljük.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><i class="bi bi-caret-right-fill"></i> Hajrá, megnézem a feladatot</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'

export default {
  name: 'Information',
  props: ['initData'],
  data() {
    let userData = this.initData.user

    return {
      userData
    }
  },
  methods:{
    openModal(){
      this.modalInstance = new Modal(document.getElementById('informationModal'))
      this.modalInstance.show()
    },
  }
}
</script>


<style scoped>
h1{
  font-size: 2rem;
  line-height: 2rem;
}
</style>