import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios'

import 'bootstrap'
import './assets/style.scss'
import './assets/custom.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)

let apiPath = "https://api.itechchallenge.hu"
const hostName = window.location.hostname
if(hostName === 'localhost' || hostName.includes('test')){
    apiPath = "https://api-test.itechchallenge.hu"
}

axios.defaults.baseURL = apiPath
axios.defaults.withCredentials = true
app.config.globalProperties.$axios = axios
app.config.globalProperties.$myApiKey = process.env.VUE_APP_API_MY_KEY
axios.defaults.headers.common['TestHash'] = process.env.VUE_APP_API_MY_KEY

app.use(router,axios)
app.mount('#app')
