<template>
  <div class="modal fade" id="taskModal" tabindex="-1" role="dialog" aria-labelledby="taskModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <form @submit.prevent="handleSubmit">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="taskModalLabel"><i class="bi bi-list-task"></i> Feladat kiválasztása</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Itt tudod kiválasztani melyik feladattal szeretnétek dolgozni.</p>
            <p>Ezt később ugyanitt, a menüben az aktuális feladat nevére kattintva, bármikor tudod állítani.</p>
            <select class="form-control" v-model="selectTask">
              <option value="0">Nincs kiválasztva</option>
              <option :value="task.taskId" v-for="task in tasks">{{task.name}}</option>
            </select>
            <div v-if="selectTaskError" class="small text-danger">
              {{ selectTaskError }}
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit" data-bs-dismiss="modal"><i class="bi bi-check2-square"></i> Kiválasztás</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";
import Information from "@/views/Information";

export default {
  name: 'TaskModal',
  props: ['initData'],
  data() {
    let data = this.initData.data
    let tasks = data.tasks

    let userData = this.initData.user
    let selectedTask = userData.selectedTask
    let selectTask = selectedTask
    if(selectTask===null){
      selectTask = 0
    }

    return {
      tasks,
      selectTask,
      selectTaskError : null,
      modalInstance : null,
    }
  },methods:{
    handleSubmit() {
      let validate = new Validate()

      this.selectTaskError = validate.isSelected(this.selectTask,'Nincs kiválasztva feladat.')

      if (this.selectTaskError) {
        return false;
      }

      let formData = new FormData()
      formData.append('task', this.selectTask)

      this.initData.loader = true
      this.$axios.post('/v2/qualifier/taskChange',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.user.selectedTask = this.selectTask
              this.initData.user.lastCode = responseData.data.lastCode

              setTimeout(() => {
                this.closeModal()
              }, 500);
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });

    },
    closeModal(){
      this.modalInstance = new Modal(document.getElementById('taskModal'))
      this.modalInstance.hide()
    },
  }
}
</script>


<style scoped>

</style>