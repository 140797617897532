<template>
  <div class="modal fade" id="gitModal" tabindex="-1" role="dialog" aria-labelledby="gitModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="gitModalLabel"><i class="bi bi-git"></i> Git használata</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-primary" role="alert">
            <div class="row">
              <div class="col-12">
                <h2>Git alapadatok</h2>
                <p><strong>Repo URL:</strong> {{userData.repoUrl}}<br/>
                  <strong>Felhasználónév:</strong> {{userData.gitUser}}<br/>
                  <strong>Jelszó / token:</strong> {{userData.gitToken}}
                </p>
              </div>
            </div>
          </div>
          <div class="alert alert-warning" role="alert">
            <div class="row">
              <div class="col-12">
                <p>Ha még sosem használtál Gitet, akkor legegyszerűbb, ha belépsz az online Gitlab felületre:<br>
                  <strong>GitLab Web IDE: </strong>
                  <span v-if="selectedTask===null">válassz feladatot, és megjelenik az elérés</span>
                  <a v-else :href="webIdeUrl" target="_blank">{{webIdeUrl}}</a>
                </p>
                <p>A linket megnyitva, és a felhasználónév / jelszó párossal belépve meg fogjátok találni a kiinduló fájlt. Ha elkezditek módosítani, akkor lehetőségetek van commitolni (bal alul), így beküldve a kódotokat.</p>
                <p>Ha szeretnéd a saját gépeden használni, vagy szeretnéd megérteni a Git működését, olvasd el a következő kis összefoglalónkat.</p>
              </div>
            </div>
          </div>
          <h2>1. Install</h2>
          <p>Git telepítés gépre (ha még nincs fenn). Először fel kell telepítened a gitet , illetve az alap beállításokat meg kell tenned. Győződj meg arról, hogy a <kbd>PATH</kbd> környezeti változóba beletegye a <kbd>git</kbd> futtatható állományt a telepítés során.<br>
            <a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git" target="_blank">https://git-scm.com/book/en/v2/Getting-Started-Installing-Git</a><br>
            <a href="https://git-scm.com/book/en/v2/Getting-Started-First-Time-Git-Setup" target="_blank">https://git-scm.com/book/en/v2/Getting-Started-First-Time-Git-Setup</a>
          </p>
          <h2>2. Clone</h2>
          <p>Ahhoz, hogy a saját gépedre letöltsd a programkódokat, (azaz a már számodra előkészített git repositoryt) a következő git paranccsal teheted ezt meg parancssorba/terminálba írva:</p>
          <p><kbd>git clone <span class="git-repo-url"></span></kbd></p>
          <p>Erről részletesebb leírást <a href="https://git-scm.com/book/en/v2/Git-Basics-Getting-a-Git-Repository#_git_cloning" target="_blank">itt találhatsz.</a></p>

          <p>Ha esetlegesen olyat ír a program, hogy nem ismeri fel a <kbd>git</kbd> parancsot, akkor nagy valószínűséggel a telepítés során/után nem állítódott be a <kbd>PATH</kbd> környezeti változóba a git futtatható állomány mappája. Ahhoz hogy ezt manuálisan beállítsd, tudnod kell a telepítés helyét (az a mappa, ahol a git futtatható állomány van). Ezek után <a href="https://superuser.com/a/284351" target="_blank">be is lehet állítani.</a></p>

          <p>A parancs kiadása során kérni fog felhasználónevet és jelszót. Az ezen a felületen megtalálható adatokat kell megadni neki.</p>

          <p><kbd>Felhasználónév: {{userData.gitUser}}</kbd><br><kbd>Git jelszó: {{userData.gitToken}}</kbd></p>
          <p>Ha permission/hozzáférési problémákat jelez, az nagy valószínűséggel azért van, mert egy írásvédett mappába akarná beletenni a kódot. Hogy ne így legyen, lépj be egy olyan könyvtárba, amely írható (pl a számítógépes felhasználóhoz tartozó <kbd>documents</kbd> vagy <kbd>dokumentumok</kbd> mappa).</p>

          <p>Ha a felületen a Git szerver jelző piros, az azt jelenti, hogy ideiglenesen nem elérhető a szerver. Ha ez sokáig (fél órán keresztül) így van, akkor nem lehet kódot letölteni, se feltölteni (a felületről sem), kérjük, hogy írjatok e-mailt.</p>

          <h2>3. Checkout</h2>
          <p>A <kbd>git clone</kbd> létrehozott egy mappát. Ebbe belelépve <kbd>cd &#x3C;<span class="git-user"></span>&#x3E;</kbd> már közel vagytok a felületen is látható kódokhoz. Ahhoz hogy a mappában lássátok, még egy parancs kiadására szükség van.</p>
          <p>A selejtezős 3 feladat a következő <a href="https://git-scm.com/book/en/v2/Git-Branching-Branches-in-a-Nutshell" target="_blank">brancheken</a> található meg: <kbd>main_dobolas</kbd>, <kbd>main_sakk</kbd>, <kbd>main_szokereso</kbd>.</p>
          <p class="small">Van még egy branch: <kbd>main_log</kbd>, itt két fájlt találtok, <kbd>results.log</kbd>, ami minden fontosabb futtatási adatot tartalmaz, illetve sikertelen fordítás esetén a <kbd>compile.log</kbd> fájlt, ami tartalmazza fordítási visszajelzést.</p>
          <p>A <kbd>dobolas</kbd> feladat kódjának eléréséhez a következő parancs kiadására van szükség: <kbd>git checkout main_dobolas</kbd></p>
          <p>Ha ez a parancs sikeresen lefut, akkor a mappában megtalálható lesz a <kbd>main.cpp</kbd>/<kbd>Guesser.hpp</kbd>, amely megegyezik a felületen látható kóddal. Kérjük ezeket a fájlokat ne töröljétek és ne is nevezzétek át.</p>

          <h2>4. Commit/kód módosítása</h2>
          <p>Ha nem értetek a githez, egyszerre csak az egyik módszerrel ajánljuk, hogy módosítsátok a kódot csapat szinten. Vagy gitből, vagy a felületről.</p>
          <p>Amikor végeztél a <kbd>main.cpp</kbd>/<kbd>Guesser.hpp</kbd> módosításával, és esetlegesen még segéd osztályt is létrehoztál (nem kötelező) <kbd>class.h</kbd>, <kbd>class.cpp</kbd>, akkor a következő parancsok kiadására van szükség ahhoz, hogy érvényesüljön a módosítás:</p>
          <p><kbd>git add main.cpp class.h class.cpp</kbd>/<kbd>git add *</kbd>, azaz <a href="https://git-scm.com/book/en/v2/Git-Basics-Recording-Changes-to-the-Repository#_tracking_files" target="_blank">mely fájlok</a> módosítását szeretnéd hogy érvényesüljön. A szerverünk úgy van felkészítve, hogy csak C++ forráskódokat képes fogadni (.cpp, .c, .h, .hpp, .hh, .cc), így kérjük, hogy csak ilyen fájlokat tegyetek fel.</p>
          <p><kbd>git commit</kbd>, amivel egy üzenettel megjelölöd a <a href="https://git-scm.com/book/en/v2/Git-Basics-Recording-Changes-to-the-Repository#_committing_changes" target="_blank">változást</a>. Ez csak a Te gépeden látszódik, így a szervergéphez nem jut még el. Ha megnyílt a szövegszerkesztő a parancs hatására, írj be egy üzenetet (hogy miről szól a változtatás), mentsd el, és lépj ki a programból. Ha nem szeretnél mindig a szövegszerkesztővel bajlódni, vagy ha nem látja a git a szövegszerkesztődet és hibát írhat ki, akkor az opcionális <kbd>git commit -m"Commit message"</kbd> kapcsolóval is meg tudod adni az üzenetet.</p>
          <p><kbd>git push</kbd>, amivel a lokális, commitolt módosításaidat <a href="https://git-scm.com/book/en/v2/Git-Basics-Working-with-Remotes#_pushing_remotes" target="_blank">feltöltöd a szerverre</a> is. Ez több helyen is megakadhat, a leggyakoribb, ha a csapattársad módosított a kódon, vagy a felületről is történt commit. Ekkor könnyű esetben egy <kbd>git pull</kbd> parancs megoldja a problémát, és lehet újra <kbd>git push</kbd> parancsot kiadni. Rosszabb esetben <a href="https://git-scm.com/book/en/v2/Git-Branching-Basic-Branching-and-Merging#_basic_merge_conflicts" target="_blank">merge conflict</a> keletkezik, amit fel kell oldani. Ennek elkerülése végett érdemes a <kbd>git pull</kbd> parancsot kiadni, mielőtt kódmódosítást végzel, és tudod, hogy más már módosított az állapoton.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="bi bi-x-square"></i> Bezárás</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'GitModal',
  props: ['initData'],
  data() {
    let userData = this.initData.user
    let data = this.initData.data
    let gitWebIdeUrl = data.gitWebIdeUrl
    let tasks = this.initData.data.tasks

    return {
      userData,
      gitWebIdeUrl,
      tasks
    }
  },
  computed:{
    selectedTask(){
      return this.initData.user.selectedTask
    },
    webIdeUrl(){
      let selectedTask = this.initData.user.selectedTask
      if(selectedTask===null){
        return null
      }
      let taskData = this.tasks.filter(function (el) {return el.taskId===selectedTask})[0]
      return this.gitWebIdeUrl + this.userData.gitUser +'/tree/main_'+ taskData.gitTaskName +'/-/'+taskData.gitDefaultFilePath
    }
  },
  watch:{
    selectedTask(){
      let selectedTask = this.initData.user.selectedTask
      let taskData = this.tasks.filter(function (el) {return el.taskId===selectedTask})[0]

      this.webIdeUrl = this.gitWebIdeUrl + this.userData.gitUser +'/tree/main_'+ taskData.gitTaskName +'/-/'+taskData.gitDefaultFilePath
    }
  }
}
</script>


<style scoped>

</style>