<template>
  <div class="modal fade" id="historyModal" tabindex="-1" role="dialog" aria-labelledby="historyModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="historyModalLabel"><i class="bi bi-terminal"></i> Futtatások</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3 mb-3">
              <select class="form-control" v-model="selectTask" @change="taskFilter">
                <option value="0">Összes futtatás</option>
                <option :value="task.taskId" v-for="task in tasks">{{task.name}}</option>
              </select>
            </div>
            <div class="col-9">
                <button class="btn btn-primary float-end" @click="Game.methods.getNewest(this.$parent,false)"><i class="bi bi-arrow-clockwise"></i> Frissítés</button>
            </div>
            <div class="col-12">
              <p class="small">A futtatások részletes eredményeit (compile.log,results.log) a <a :href="gitWebIdeUrl.replace('/-/ide/project','')+userData.gitUser+'/-/commits/main_log/'" target="_blank">git repotok main_log branch</a>ében is nyomon követhetitek.</p>
              <p class="small">A tesztesetek számára kattintva részletesen láthatjátok, tesztesetenként hány pontot kaptatok, vagy időlimit, vagy nem ötbetűs angol szó (non-english word), vagy memóriahiba(ez lehet memória limit túllépés, vagy pl. túlnyúltatok egy tömbön, vagy null pointert) miatt nem sikerült, illetve error esetén a programotok exit kódja nem 0 lett.</p>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                  <tr>
                    <th>Feladat azonosító</th>
                    <th>Git hash</th>
                    <th>Fordítás</th>
                    <th>Idő limit</th>
                    <th>Memória hiba</th>
                    <th>Pontszám</th>
                    <th>Tesztesetek</th>
                    <th>Futtatás vége</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(history,index) in histories">
                    <tr>
                      <td>{{history.taskName}}-{{history.runId}}</td>
                      <td><a :href="gitWebIdeUrl.replace('/-/ide/project','')+userData.gitUser+'/-/commit/'+history.gitHash" target="_blank" title="Commit a GitLab felületen"><i class="bi bi-git"></i></a> {{history.gitHash}}</td>
                      <td><span v-if="history.compileSuccess==1" class="text-success" title="Sikeres fordítás"><i class="bi bi-check"></i></span><span v-else class="text-danger" title="Sikertelen fordítás"><i class="bi bi-x"></i></span></td>
                      <td><span v-if="history.timeLimitExceeded==0" class="text-success" title="Nem volt idő túllépés"><i class="bi bi-x"></i></span><span v-else class="text-danger" title="Idő limit túl lett lépve"><i class="bi bi-check"></i></span></td>
                      <td><span v-if="history.memoryLimitExceeded==0" class="text-success" title="Nem volt memória túllépés"><i class="bi bi-x"></i></span><span v-else class="text-danger" title="Memória limit túl lett lépve"><i class="bi bi-check"></i></span></td>
                      <td>{{history.sumScore}}</td>
                      <td data-bs-toggle="collapse" :href="'#collapseRuns'+index" role="button" aria-expanded="false" :aria-controls="'collapseRuns'+index"><i class="bi bi-card-list"></i> {{Object.keys(history.runs).length}}</td>
                      <td>{{history.createDateTime}}</td>
                    </tr>
                    <tr :id="'collapseRuns'+index" class="collapse">
                      <td colspan="8">
                        <div class="row">
                            <template v-for="run in runsModify(history.runs,history.taskName)">
                              <div class="col-3">
                              {{run.key}}: {{run.value}}<br>
                              </div>
                            </template>
                        </div>

                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><i class="bi bi-x-square"></i> Bezárás</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from 'bootstrap'
import Game from '@/views/Game'

export default {
  name: 'HistoryModal',
  props: ['initData'],
  components:{
    Game
  },
  data() {
    let userData = this.initData.user
    let histories = userData.histories
    let data = this.initData.data
    let tasks = data.tasks
    let gitWebIdeUrl = data.gitWebIdeUrl
    return {
      Game,
      tasks,
      selectTask: 0,
      histories,
      userData,
      gitWebIdeUrl
    }
  },
  methods:{
    runsModify(obj,task){
      let index = ''
      if(task==="Sakk" || task==="Dobolás") {
        index = Object.keys(obj).sort()
      }else {
        index = Object.keys(obj)
      }

      let sorted = index.map((v) => {
        return {key: v, value: obj[v]}
      })
      return sorted
    },
    taskFilter(){
      let selectTask = this.selectTask
      let histories = this.userData.histories
      if(selectTask!=='0'){
        this.histories = histories.filter(function (el) {return el.taskId===selectTask})
      }else{
        this.histories = histories
      }

    }
  }
}
</script>


<style scoped>
table i{
  cursor: pointer;
}
</style>